import { render, staticRenderFns } from "./markets.vue?vue&type=template&id=6f515b58"
import script from "./markets.vue?vue&type=script&lang=js"
export * from "./markets.vue?vue&type=script&lang=js"
import style0 from "./markets.vue?vue&type=style&index=0&id=6f515b58&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.11.1_babel-core@6.26.3_cache-loader@4.1.0_css-loader@5.2.7_lodash@4.17.21_prett_mslvjkx5fqxq5hwkswdqi36zjm/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SaleCountDownCard: require('/vercel/path0/components/SaleCountDownCard.vue').default,IexPriceChange: require('/vercel/path0/components/IexPriceChange.vue').default,TrkdNewsFeed: require('/vercel/path0/components/TrkdNewsFeed.vue').default,IexOverviewTable: require('/vercel/path0/components/IexOverviewTable.vue').default,StyledTabs: require('/vercel/path0/components/StyledTabs.vue').default})
