import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function _toConsumableArray(r) {
  return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread();
}
function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _iterableToArray(r) {
  if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r);
}
function _arrayWithoutHoles(r) {
  if (Array.isArray(r)) return _arrayLikeToArray(r);
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import { ref } from "vue";
import { useSessionStorage } from "@vueuse/core";
import StyledTabs from "~/components/StyledTabs";
import ReferralCard from "~/components/account/referralCardV1.vue";
import lifecycleLog from "~/mixins/lifecycleLog.js";
import useBaseUtils from "~/functions/useBaseUtils";
import useBusinessMode from "~/functions/useBusinessMode";
import IexOverviewTable from "~/components/IexOverviewTable.vue";
export default defineComponent({
  components: {
    StyledTabs: StyledTabs,
    ReferralCard: ReferralCard,
    IexOverviewTable: IexOverviewTable
  },
  mixins: [lifecycleLog("/markets?fid=1")],
  setup: function setup() {
    var _useNuxtApp = useNuxtApp(),
      $vuetify = _useNuxtApp.$vuetify;
    var _useBaseUtils = useBaseUtils(),
      refCode = _useBaseUtils.refCode,
      dev = _useBaseUtils.dev,
      isDrawerHidden = _useBaseUtils.isDrawerHidden,
      freeTier = _useBaseUtils.freeTier;
    var _useBusinessMode = useBusinessMode(),
      hidePrice = _useBusinessMode.hidePrice;
    var showTikrWelcomeMsg = useSessionStorage("showTikrWelcomeMsg", true);
    var shouldFillCols = computed(function () {
      return isDrawerHidden.value && !$vuetify.breakpoint.xs;
    });
    var toggleWelcomeMsg = function toggleWelcomeMsg(bool) {
      showTikrWelcomeMsg.value = bool;
    };
    var tickerArr = ref([{
      tid: "6179710",
      cid: "6160262",
      iex: "SPY",
      name: "S&P 500 (SPY)"
    }, {
      tid: "37284618",
      cid: "8108558",
      iex: "QQQ",
      name: "NASDAQ (QQQ)"
    }, {
      tid: "6179254",
      cid: "6184218",
      iex: "DIA",
      name: "Dow Jones (DIA)"
    }, {
      tid: "6179419",
      cid: "8797658",
      iex: "IWM",
      name: "Russell 2000 (IWM)"
    }]);
    var sectorArr = ref([{
      tid: "6179739",
      cid: "8795639",
      iex: "XLK",
      name: "Technology"
    }, {
      tid: "6179733",
      cid: "8795490",
      iex: "XLV",
      name: "Health Care"
    }, {
      tid: "311938864",
      cid: "308648931",
      iex: "XLRE",
      name: "Real Estate"
    }, {
      tid: "6179737",
      cid: "8795483",
      iex: "XLF",
      name: "Financials"
    }, {
      tid: "6179740",
      cid: "8795452",
      iex: "XLU",
      name: "Utilities"
    }, {
      tid: "571275348",
      cid: "557642090",
      iex: "XLC",
      name: "Communication Services"
    }, {
      tid: "6179735",
      cid: "8795473",
      iex: "XLY",
      name: "Consumer Discretionary"
    }, {
      tid: "6179734",
      cid: "2668776",
      iex: "XLP",
      name: "Consumer Staples"
    }, {
      tid: "6179738",
      cid: "8795505",
      iex: "XLI",
      name: "Industrials"
    }, {
      tid: "6179736",
      cid: "8795479",
      iex: "XLE",
      name: "Energy"
    }, {
      tid: "6179732",
      cid: "8795509",
      iex: "XLB",
      name: "Materials"
    }, {
      tid: "27069133",
      cid: "32993612",
      iex: "ITA",
      name: "Aerospace & Defense"
    }, {
      tid: "6179409",
      cid: "8797590",
      iex: "IBB",
      name: "Biotechnology"
    }, {
      tid: "27069137",
      cid: "27446899",
      iex: "IHI",
      name: "Medical Devices"
    }, {
      tid: "268018200",
      cid: "262340397",
      iex: "REET",
      name: "REITs"
    }, {
      tid: "6179406",
      cid: "8796823",
      iex: "SOXX",
      name: "Semiconductor"
    }, {
      tid: "6179405",
      cid: "8796877",
      iex: "IGV",
      name: "Software"
    }]);
    var regionArr = ref([{
      tid: "6179400",
      cid: "8797589",
      iex: "EFA",
      name: "Developed ex-US"
    }, {
      tid: "223269555",
      cid: "223308554",
      iex: "IEMG",
      name: "Emerging Markets"
    }, {
      tid: "206594417",
      cid: "206594413",
      iex: "FM",
      name: "Frontier"
    }, {
      tid: "6179364",
      cid: "6456769",
      iex: "EWC",
      name: "Canada"
    }, {
      tid: "6179368",
      cid: "6456828",
      iex: "EWU",
      name: "United Kingdom"
    }, {
      tid: "6179373",
      cid: "6456912",
      iex: "EWP",
      name: "Spain"
    }, {
      tid: "6179376",
      cid: "6457034",
      iex: "EWG",
      name: "Germany"
    }, {
      tid: "6179372",
      cid: "6456851",
      iex: "EWD",
      name: "Sweden"
    }, {
      tid: "6179360",
      cid: "6157790",
      iex: "EWA",
      name: "Australia"
    }, {
      tid: "129248934",
      cid: "129248930",
      iex: "MCHI",
      name: "China"
    }, {
      tid: "6179380",
      cid: "6457117",
      iex: "EWJ",
      name: "Japan"
    }, {
      tid: "6179367",
      cid: "6456803",
      iex: "EWS",
      name: "Singapore"
    }, {
      tid: "146036928",
      cid: "146036916",
      iex: "INDA",
      name: "India"
    }, {
      tid: "115621718",
      cid: "115621716",
      iex: "ERUS",
      name: "Russia"
    }, {
      tid: "6179363",
      cid: "6456547",
      iex: "EWZ",
      name: "Brazil"
    }, {
      tid: "6179378",
      cid: "6457113",
      iex: "EWW",
      name: "Mexico"
    }]);
    var assetArr = ref([{
      tid: "274567848",
      cid: "271828010",
      iex: "COMT",
      name: "Commodities"
    }, {
      tid: "20328799",
      cid: "8956538",
      iex: "IAU",
      name: "Gold"
    }, {
      tid: "26971802",
      cid: "22769902",
      iex: "SLV",
      name: "Silver"
    },
    // {
    //   tid: "6179710",
    //   cid: "6160262",
    //   iex: "XXXXXX",
    //   name: "Bitcoin",
    //   notops: 1,
    // }, // FIXME:
    // {
    //   tid: "6179710",
    //   cid: "6160262",
    //   iex: " XXXXXX  ",
    //   name: "Ethereum",
    //   notops: 1,
    // }, // FIXME:
    {
      tid: "26667648",
      cid: "33939205",
      iex: "USO",
      name: "Oil"
    }, {
      tid: "33938325",
      cid: "33939205",
      iex: "UNG",
      name: "Natural Gas"
    }, {
      tid: "170834321",
      cid: "170834319",
      iex: "GOVT",
      name: "U.S. Treasuries"
    }, {
      tid: "36796575",
      cid: "37088920",
      iex: "MUB",
      name: "Municipals"
    }, {
      tid: "8056905",
      cid: "22699527",
      iex: "TIP",
      name: "TIPS"
    }, {
      tid: "39599142",
      cid: "39585832",
      iex: "EMB",
      name: "EM Govt Bonds"
    }, {
      tid: "6179386",
      cid: "8797348",
      iex: "LQD",
      name: "U.S. Corp Investment Grade"
    }, {
      tid: "33757859",
      cid: "33837611",
      iex: "HYG",
      name: "U.S. High Yield"
    }]);
    var factorArr = ref([{
      tid: "233327242",
      cid: "233327240",
      iex: "VLUE",
      name: "Value"
    }, {
      tid: "240359441",
      cid: "240359439",
      iex: "QUAL",
      name: "Quality"
    }, {
      tid: "233326963",
      cid: "233326961",
      iex: "MTUM",
      name: "Momentum"
    }, {
      tid: "233327259",
      cid: "233327257",
      iex: "SIZE",
      name: "Size"
    }, {
      tid: "6179415",
      cid: "8797643",
      iex: "IWF",
      name: "Growth"
    }, {
      tid: "411613006",
      cid: "381286509",
      iex: "ESGU",
      name: "ESG"
    }, {
      tid: "7690511",
      cid: "22699479",
      iex: "DVY",
      name: "Dividend"
    }, {
      tid: "266067422",
      cid: "263934819",
      iex: "DGRO",
      name: "Dividend Growth"
    }, {
      tid: "129248933",
      cid: "129248929",
      iex: "HDV",
      name: "High Dividend Yield"
    }, {
      tid: "304310166",
      cid: "290407876",
      iex: "IVLU",
      name: "International Value"
    }, {
      tid: "281963030",
      cid: "276220583",
      iex: "IQLT",
      name: " International Quality"
    }, {
      tid: "281963031",
      cid: "276220584",
      iex: "IMTM",
      name: " International Momentum"
    }, {
      tid: "6179384",
      cid: "8797798",
      iex: "IVV",
      name: "Large Cap"
    }, {
      tid: "6179404",
      cid: "8798152",
      iex: "IJH",
      name: "Mid Cap"
    }, {
      tid: "6179433",
      cid: "8798175",
      iex: "IJR",
      name: "Small Cap"
    }, {
      tid: "23565247",
      cid: "25287806",
      iex: "IWC",
      name: "Micro Cap"
    }]);
    var overviewTab = ref(0);
    var overviewTabs = [{
      label: "Most Active",
      value: "active"
    }, {
      label: "Gainers",
      value: "gainers"
    }, {
      label: "Losers",
      value: "losers"
    }];
    var secondTab = ref(0);
    var secondTabs = [{
      label: "Sectors",
      value: "sectors"
    }, {
      label: "Regions",
      value: "regions"
    }, {
      label: "Assets",
      value: "assets"
    }, {
      label: "Factors",
      value: "factors"
    }];
    var dataTblDiv = ref({});
    var sectorColumns = ref([{
      text: "ETF",
      columntype: "name",
      value: "name",
      "class": "px-1",
      align: "left"
    }, {
      text: "Last Price",
      columntype: "lastPrice",
      value: "latestPrice",
      align: "right",
      "class": "px-1 lastprice-cell",
      cellClass: "lastprice-cell"
    }, {
      text: "% Change",
      columntype: "changePercent",
      value: "changePercent",
      align: "right",
      "class": "px-1 changepercent-cell",
      cellClass: "changepercent-cell"
    }]);
    var smallScreen = ref(["xs", "sm"]);
    return {
      refCode: refCode,
      dev: dev,
      hidePrice: hidePrice,
      shouldFillCols: shouldFillCols,
      tickerArr: tickerArr,
      smallScreen: smallScreen,
      overviewTab: overviewTab,
      overviewTabs: overviewTabs,
      secondTab: secondTab,
      secondTabs: secondTabs,
      sectorColumns: sectorColumns,
      sectorArr: sectorArr,
      regionArr: regionArr,
      assetArr: assetArr,
      factorArr: factorArr,
      dataTblDiv: dataTblDiv,
      showTikrWelcomeMsg: showTikrWelcomeMsg,
      toggleWelcomeMsg: toggleWelcomeMsg,
      isDrawerHidden: isDrawerHidden,
      freeTier: freeTier
    };
  },
  computed: {
    data: function data() {
      // FIXME: make this a composible / computed property?
      return this.$store.state.trkd.marketHeadlines;
    },
    authenticated: function authenticated() {
      return Boolean(this.$store.state.user);
    },
    activeOverview: function activeOverview() {
      var idx = this.overviewTab;
      var active = this.overviewTabs[idx];
      return this[active.value];
    },
    activeSecondList: function activeSecondList() {
      var idx = this.secondTab;
      var active = this.secondTabs[idx];
      return this[active.value];
    },
    showRefCard: {
      get: function get() {
        return this.$store.state.config.showRefCard;
      },
      set: function set(value) {
        this.$store.commit("config/setConfig", {
          type: "showRefCard",
          value: value
        });
      }
    },
    ciqData: function ciqData() {
      return this.$store.state.watchlist.ciq || {};
    },
    quotes: function quotes() {
      return this.$store.state.iex.lastQuotes || {};
    },
    dataTblHeight: function dataTblHeight() {
      return this.dataTblDiv.clientHeight;
    },
    newsHeight: function newsHeight() {
      return this.smallScreen.includes(this.$vuetify.breakpoint.name) ? "85vh" : "955px";
    },
    actualNewsHeight: function actualNewsHeight() {
      var ref = this.$refs.newsCont;
      return ref ? ref.clientHeight : "na";
    },
    loadingOverview: function loadingOverview() {
      // FIXME: this should change?
      return this.$store.state.advIex.loading.overview;
    },
    advIexData: function advIexData() {
      return this.$store.state.advIex.lastQuote;
    },
    active: function active() {
      var data = this.advIexData;
      var arr = this.$store.state.advIex.movers.active;
      return arr.map(function (m) {
        var d = data[m.symbol] || {};
        return _objectSpread(_objectSpread({}, m), d);
      });
    },
    losers: function losers() {
      var data = this.advIexData;
      var arr = this.$store.state.advIex.movers.losers;
      return arr.map(function (m) {
        var d = data[m.symbol] || {};
        return _objectSpread(_objectSpread({}, m), d);
      });
    },
    gainers: function gainers() {
      var data = this.advIexData;
      var arr = this.$store.state.advIex.movers.gainers;
      return arr.map(function (m) {
        var d = data[m.symbol] || {};
        return _objectSpread(_objectSpread({}, m), d);
      });
    },
    sectors: function sectors() {
      var data = this.advIexData;
      var arr = this.sectorArr;
      return arr.map(function (m) {
        var d = data[m.iex] || {};
        return _objectSpread(_objectSpread({}, m), d);
      });
    },
    regions: function regions() {
      var data = this.advIexData;
      var arr = this.regionArr;
      return arr.map(function (m) {
        var d = data[m.iex] || {};
        return _objectSpread(_objectSpread({}, m), d);
      });
    },
    assets: function assets() {
      var data = this.advIexData;
      var arr = this.assetArr;
      return arr.map(function (m) {
        var d = data[m.iex] || {};
        return _objectSpread(_objectSpread({}, m), d);
      });
    },
    factors: function factors() {
      var data = this.advIexData;
      var arr = this.factorArr;
      return arr.map(function (m) {
        var d = data[m.iex] || {};
        return _objectSpread(_objectSpread({}, m), d);
      });
    }
  },
  mounted: function mounted() {
    var _this = this;
    var tickersToFetch = _toConsumableArray(this.tickerArr);
    var tickersToTops = [].concat(_toConsumableArray(this.sectorArr), _toConsumableArray(this.regionArr), _toConsumableArray(this.assetArr), _toConsumableArray(this.factorArr));
    this.$store.dispatch("advIex/marketsMounted", {
      tickers: tickersToFetch,
      tops: tickersToTops.map(function (m) {
        return m.iex;
      })
    });
    window.onfocus = function () {
      // TODO: is this creating the interval?
      // I don't think this is creating the interval
      _this.$store.dispatch("advIex/createInterval", {
        intervalKey: "overview",
        tickers: tickersToFetch
      });
    };
    window.onblur = function () {
      // FIXME: this ins't clearing the interva;?
      _this.$store.dispatch("advIex/clearInterval", {
        intervalKey: "overview"
      });
    };
    this.dataTblDiv = document.querySelector("#marketDataTblRow");
  },
  destroyed: function destroyed() {
    this.$store.dispatch("advIex/clearInterval", {
      intervalKey: "overview"
    });
  }
});