import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import useBaseUtils from "~/functions/useBaseUtils";
export default {
  __name: 'IexPriceChange',
  props: {
    ticker: {
      type: Object,
      "default": function _default() {
        return {
          tid: "6179710",
          cid: "6160262",
          iex: "SPY",
          name: "S&P 500 (SPY)"
        };
      }
    },
    ciqData: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    quotes: {
      type: Object,
      "default": function _default() {
        return {};
      }
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var _useBaseUtils = useBaseUtils(),
      refCode = _useBaseUtils.refCode,
      dev = _useBaseUtils.dev;
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store;
    var liveData = computed(function () {
      return props.quotes[props.ticker.iex] || {};
    });
    var ts = computed(function () {
      return liveData.value.ts || [];
    });
    var tsPrice = computed(function () {
      return ts.value.filter(function (f) {
        return f.close;
      }).map(function (m) {
        return m.close;
      });
    });
    var loading = computed(function () {
      var _$store$state$advIex$;
      return (_$store$state$advIex$ = $store.state.advIex.loading) === null || _$store$state$advIex$ === void 0 ? void 0 : _$store$state$advIex$.lastQuote;
    });
    var isGain = computed(function () {
      var latest = liveData.value.latestPrice;
      var prev = liveData.value.previousClose;
      return latest > prev;
    });
    return {
      __sfc: true,
      props: props,
      refCode: refCode,
      dev: dev,
      $store: $store,
      liveData: liveData,
      ts: ts,
      tsPrice: tsPrice,
      loading: loading,
      isGain: isGain
    };
  }
};