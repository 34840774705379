import useBaseUtils from "~/functions/useBaseUtils";
export default {
  __name: 'referralCardV1',
  props: {
    finePrint: {
      type: Array,
      "default": function _default() {
        return ["20% off subscription valid until June 1 2021", "Referral credits distributed to referrer within 30 days of referee subscription. Total credit limited to the subscription you have purchased. Offer valid until June 1 2021"];
      }
    },
    showclose: {
      type: Boolean,
      "default": false
    },
    visible: {
      type: Boolean,
      "default": false
    }
  },
  setup: function setup(__props) {
    var copied = ref(false);
    var _useNuxtApp = useNuxtApp(),
      $vuetify = _useNuxtApp.$vuetify;
    var _useBaseUtils = useBaseUtils(),
      darkActive = _useBaseUtils.darkActive,
      refCode = _useBaseUtils.refCode,
      dev = _useBaseUtils.dev,
      payingCustomer = _useBaseUtils.payingCustomer,
      I18nFn = _useBaseUtils.I18nFn;
    var paymentUnderline = ref("Limited Time Offer:");
    var paymentDetails = ref("we are offering a 50% lifetime discount if you sign up for the new Pro plan today!");
    var paymentAction = ref("Sign up here");
    var iconColor = "#a5a5a5";
    var wrapperStyles = computed(function () {
      return {
        "--bg": darkActive.value ? "#383838" : "#F8F8F8",
        "--border-color": darkActive.value ? "#555" : "#D9D9D9",
        "--color": darkActive.value ? "#fff" : "#2f3033"
      };
    });
    var refLink = computed(function () {
      return dev.value ? "https://devs.tikr.com/register?ref=".concat(refCode.value) : "https://app.tikr.com/register?ref=".concat(refCode.value);
    });
    var tooltipMsg = computed(function () {
      var msg = copied.value ? "Link copied to clipboard" : "Click to copy to clipboard";
      return I18nFn(msg);
    });
    var copyLink = function copyLink() {
      // const link = document.getElementById("refLink").innerText
      var el = document.createElement("textarea");
      el.value = refLink.value;
      try {
        // copy the referal link to the clipboard here
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        copied.value = true;
        console.log("copied: ", refLink.value);
      } catch (error) {
        if (dev.value) {
          console.log("copy link error: ", error);
        } // perform the backup copyLink logic?
      }
    };
    return {
      __sfc: true,
      copied: copied,
      $vuetify: $vuetify,
      darkActive: darkActive,
      refCode: refCode,
      dev: dev,
      payingCustomer: payingCustomer,
      I18nFn: I18nFn,
      paymentUnderline: paymentUnderline,
      paymentDetails: paymentDetails,
      paymentAction: paymentAction,
      iconColor: iconColor,
      wrapperStyles: wrapperStyles,
      refLink: refLink,
      tooltipMsg: tooltipMsg,
      copyLink: copyLink
    };
  }
};