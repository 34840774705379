"use strict"
const devStage = "dev"
const lambdaStage = process.env.LAMBDA_STAGE

export default (
  logName = "Missing Parameter",
  showBeforeHooks = false,
  showUpdatedHooks = false
) => {
  const baseHooks = {
    created() {
      if (lambdaStage === devStage) {
        console.log(`${logName} created called`)
      }
    },
    mounted() {
      if (lambdaStage === devStage) {
        console.log(`${logName} mounted called`)
      }
    },
    destroyed() {
      if (lambdaStage === devStage) {
        console.log(`${logName} destroy called`)
      }
    },
  }

  const beforeHooks = {
    beforeCreate() {
      if (lambdaStage === devStage) {
        console.log(`${logName} beforeCreate called`)
      }
    },
    beforeMount() {
      if (lambdaStage === devStage) {
        console.log(`${logName} beforeMount called`)
      }
    },
    beforeDestroy() {
      if (lambdaStage === devStage) {
        console.log(`${logName} beforeDestroy called`)
      }
    },
  }

  const updatedHooks = {
    beforeUpdate() {
      if (lambdaStage === devStage) {
        console.log(`${logName} beforeUpdate called`)
      }
    },
    updated() {
      if (lambdaStage === devStage) {
        console.log(`${logName} updated called`)
      }
    },
  }
  let result = baseHooks
  if (showBeforeHooks) {
    result = { ...result, ...beforeHooks }
  }
  if (showUpdatedHooks) {
    result = { ...result, ...updatedHooks }
  }
  return result
}
