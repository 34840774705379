import useBaseUtils from "~/functions/useBaseUtils"

export default {
  __name: 'SaleCountDownCard',
  setup(__props) {

const { refCode } = useBaseUtils()

return { __sfc: true,refCode }
}

}