var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"pb-0",attrs:{"fluid":""}},[_c('feature-toggle',{attrs:{"name":"show-sale-countdown","value":true}},[(_vm.freeTier)?_c('v-row',{class:{ 'mx-3': _vm.$vuetify.breakpoint.smAndUp },attrs:{"dense":"","justify":"center","align":"start"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('SaleCountDownCard')],1)],1):_vm._e()],1),_vm._v(" "),_c('v-row',{attrs:{"dense":"","justify":"center","align":"start"}},[(_vm.refCode)?_c('v-col',{attrs:{"cols":"12"}},[_c('ReferralCard',{attrs:{"visible":_vm.showTikrWelcomeMsg,"showclose":true},on:{"hidecard":() => _vm.toggleWelcomeMsg(false)}})],1):_vm._e(),_vm._v(" "),_c('v-col',{staticClass:"pb-5 pt-2",class:{ 'mt-2': !_vm.showTikrWelcomeMsg },attrs:{"cols":"12"}},[_c('v-row',{staticClass:"flex-nowrap",class:{
          xscroll: _vm.smallScreen.includes(_vm.$vuetify.breakpoint.name),
          'mx-3': _vm.$vuetify.breakpoint.smAndUp,
        },attrs:{"justify":_vm.smallScreen.includes(_vm.$vuetify.breakpoint.name) ? 'start' : 'center',"dense":""}},_vm._l((_vm.tickerArr),function(ticker){return _c('v-col',{key:ticker.tid,attrs:{"align":"center","justify":"center","cols":_vm.shouldFillCols ? '3' : 'auto',"md":"3"}},[_c('IexPriceChange',{attrs:{"ticker":ticker,"quotes":_vm.advIexData,"ciq-data":_vm.ciqData}})],1)}),1)],1)],1),_vm._v(" "),_c('v-row',{staticClass:"markets-data-container",class:{
      'mx-3': _vm.$vuetify.breakpoint.smAndUp,
    },attrs:{"dense":"","justify":"center","align":"start"}},[_c('v-col',{ref:"newsCont",staticClass:"pt-0 px-1",attrs:{"cols":"12","md":"6","lg":"8"}},[_c('TrkdNewsFeed',{attrs:{"news-height":_vm.newsHeight}})],1),_vm._v(" "),_c('v-col',{ref:"marketDataTblRow",staticClass:"pt-0",attrs:{"id":"marketDataTblRow","cols":"12","md":"6","lg":"4"}},[_c('v-row',{attrs:{"dense":"","justify":"center"}},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('StyledTabs',{attrs:{"tablist":_vm.overviewTabs,"old-layout":true,"show-arrows":true,"grow":true},on:{"change":(_, idx) => {
                _vm.overviewTab = idx
              }}},[_c('v-card',{staticClass:"mt-2 pt-0 overview-table-card",attrs:{"flat":"","outlined":""}},[(_vm.loadingOverview)?_c('div',{staticClass:"loader-wrapper"},[_c('v-progress-circular',{staticClass:"quote mx-auto d-block my-8",attrs:{"size":70,"width":7,"color":"primaryAction","indeterminate":""}})],1):_c('IexOverviewTable',{key:_vm.overviewTab,attrs:{"tickers":_vm.activeOverview}})],1)],1)],1),_vm._v(" "),_c('v-col',{staticClass:"pt-4",attrs:{"cols":"12"}},[_c('StyledTabs',{attrs:{"old-layout":true,"tablist":_vm.secondTabs,"show-arrows":true,"grow":true},on:{"change":(_, idx) => (_vm.secondTab = idx)}},[_c('v-card',{staticClass:"mt-2 py-0",attrs:{"flat":"","outlined":""}},[(_vm.loadingOverview)?_c('div',{staticClass:"loader-wrapper"},[_c('v-progress-circular',{staticClass:"quote mx-auto d-block my-8",attrs:{"size":70,"width":7,"color":"primaryAction","indeterminate":""}})],1):_c('IexOverviewTable',{key:_vm.secondTab,attrs:{"columnheaders":_vm.sectorColumns,"tickers":_vm.activeSecondList}})],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }