var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{attrs:{"flat":"","outlined":"","height":"100%"}},[_c('v-container',[_c('v-row',{attrs:{"dense":"","no-gutters":""}},[_c('v-col',{class:{
          'text-left': _vm.$vuetify.breakpoint.lgAndUp,
          'text-center': !_vm.$vuetify.breakpoint.lgAndUp,
          'align-self-xl-center': true,
        },attrs:{"justify":"center","align":"center","cols":"12","lg":"auto"}},[_c('nuxt-link',{class:{
            'is-link': true,
            'accent--text': _vm.$vuetify.theme.dark,
            'font-weight-medium': true,
          },attrs:{"to":`/stock/about?cid=${_vm.ticker.cid}&tid=${_vm.ticker.tid}&ref=${_setup.refCode}`}},[_c('span',[_vm._v("\n            "+_vm._s(_vm.ticker.name)+"\n          ")])]),_vm._v(" "),(!_setup.loading && _setup.liveData)?[_c('br'),_vm._v(" "),_c('WatchlistVuetifyLastPrice',{attrs:{"livequote":_setup.liveData,"latest-price":_setup.liveData.latestPrice,"latest-update":_setup.liveData.latestUpdate,"ciq-data":_vm.ciqData[_vm.ticker.tid]}}),_vm._v(" "),_c('WatchlistVuetifyChangePercent',{attrs:{"tid":_vm.ticker.tid,"latest-price":_setup.liveData.latestPrice,"previous-close":_setup.liveData.previousClose,"ciq-data":_vm.ciqData}})]:_vm._e()],2),_vm._v(" "),(_setup.loading)?_c('v-col',{attrs:{"align":"left","justify":"left","cols":"12"}},[_c('v-skeleton-loader',{staticClass:"skeleton-loader",attrs:{"type":"table-cell"}})],1):(_setup.liveData?.ts)?_c('v-col',{staticClass:"flex-grow-1 align-self-lg-center",attrs:{"justify":"center","align":"center","cols":"12","lg":"auto"}},[(_setup.tsPrice.length > 0)?_c('v-sheet',{attrs:{"max-width":"calc(100%)"}},[_c('v-sparkline',{attrs:{"gradient":[_setup.isGain ? '#4CAF50' : '#F44336'],"value":_setup.tsPrice,"smooth":1,"line-width":2,"height":"70","auto-draw":""}})],1):_vm._e()],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }