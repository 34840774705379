export default {
  __name: 'Countdown',
  props: {
    endTime: {
      type: String,
      required: true
    }
  },
  setup: function setup(__props) {
    var props = __props; // Reactive state for time left
    var timeLeft = ref({
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    }); // RequestAnimationFrame variable
    var frameId;
    var _updateCountdown = function updateCountdown() {
      if (typeof window !== "undefined") {
        var now = new Date();
        var targetDateTime = new Date(props.endTime).getTime();
        var difference = targetDateTime - now.getTime();
        if (difference > 0) {
          timeLeft.value.days = Math.floor(difference / (1000 * 60 * 60 * 24));
          timeLeft.value.hours = Math.floor(difference / (1000 * 60 * 60) % 24);
          timeLeft.value.minutes = Math.floor(difference / 1000 / 60 % 60);
          timeLeft.value.seconds = Math.floor(difference / 1000 % 60);
          frameId = requestAnimationFrame(_updateCountdown);
        } else {
          timeLeft.value.days = 0;
          timeLeft.value.hours = 0;
          timeLeft.value.minutes = 0;
          timeLeft.value.seconds = 0; // Cancel the animation frame request
          cancelAnimationFrame(frameId);
        }
      }
    };
    onMounted(function () {
      frameId = requestAnimationFrame(_updateCountdown);
    });
    onUnmounted(function () {
      cancelAnimationFrame(frameId);
    });
    return {
      __sfc: true,
      props: props,
      timeLeft: timeLeft,
      frameId: frameId,
      updateCountdown: _updateCountdown
    };
  }
};