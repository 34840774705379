import useBaseUtils from "~/functions/useBaseUtils"
import useNumberFormatters from "~/functions/useNumberFormatters"


export default {
  __name: 'IexOverviewTable',
  props: {
  title: {
    type: String,
    default: "",
  },
  tickers: {
    type: Array,
    default: () => [],
  },
  disablePagination: {
    type: Boolean,
    default: true,
  },
  columnheaders: {
    type: Array,
    default: () => [
      {
        text: "Ticker",
        columntype: "ts",
        value: "symbol",
        align: "left",
        class: "px-1",
        cellClass: "px-1",
      },
      {
        text: "Last Price",
        columntype: "lastPrice",
        value: "latestPrice",
        align: "right",
        class: "px-1",
      },
      {
        text: "Change",
        columntype: "change",
        value: "change",
        align: "right",
        class: "px-1",
      },
      {
        text: "% Change",
        columntype: "changePercent",
        value: "changePercent",
        align: "right",
        class: "px-1",
      },
    ],
  },
},
  setup(__props) {



const { refCode } = useBaseUtils()
const { displayFloat } = useNumberFormatters()
const changeColorClass = (y0, y1) => {
  try {
    const v0 = parseFloat(y0)
    const v1 = parseFloat(y1)
    return v0 <= v1 ? "green--text" : "red--text"
  } catch (_) {
    return "green--text"
  }
}

const getCellClasses = (headers, value) => {
  const item = headers.find((item) => item.value === value)
  const str = item.cellClass || ""
  if (str) {
    if (str.includes(" ")) {
      const obj = {}
      str.split(" ").forEach((key) => {
        obj[key] = true
      })
      return obj
    } else {
      return { [str]: true }
    }
  }
  return {}
}

return { __sfc: true,refCode, displayFloat, changeColorClass, getCellClasses }
}

}